<template>
  <div>
    <div class="Polaris-Page">
      <div v-if="orderCharge.id != null">
        <PolarisBanner :title=" $t('dashboard.order.bannerCharge.title') "
          :message="$t('dashboard.order.bannerCharge.message')" @action="fnApiUpdateCharge(orderCharge.id)"
          bannerType="informational" :loading="loading.charge" :actionLabel="$t('general.button.appeal')"
          v-if="orderCharge.status == 'pending' " />

        <PolarisBanner :title="$t('dashboard.order.bannerCharge.title')"
          :message="$t('dashboard.order.bannerCharge.messageReview')" bannerType="informational"
          :loading="loading.charge" v-if="orderCharge.status == 'review'" />

        <PolarisBanner bannerType="informational" :title="$t('dashboard.order.bannerCharge.title')"
          v-if="orderCharge.status =='won'">
          <template v-slot:message-secction>
            <p>
              <span v-text="$t('dashboard.order.appeal') + ': ' "></span>
              <strong class="text-success" v-text="$t('dashboard.order.appealStatus.won') "></strong>
            </p>
          </template>
        </PolarisBanner>

        <PolarisBanner bannerType="informational" :title="$t('dashboard.order.bannerCharge.title')"
          v-if="orderCharge.status =='lost'">
          <template v-slot:message-secction>
            <p>
              <span v-text="$t('dashboard.order.appeal') + ': ' "></span>
              <strong class="text-danger" v-text="$t('dashboard.order.appealStatus.lost') "></strong>
              <br>
              <span>There is a charge of </span>
              <strong class="text-danger"
                v-text="fnFormatPrice( ( orderCharge.charge_amount ) , orderInformation.currency )"> </strong>
            </p>
          </template>
        </PolarisBanner>
      </div>
      <div v-else>
        <div v-if="orderInformation.order_status != 'cancelled'">
          <PolarisBanner title="Order payment status" bannerType="informational"
            message="The service to send the package will be enabled when the payment status changes to 'paid'."
            v-if="orderInformation.status == 'pending'" />
          <PolarisBanner title="The order was paid, generate the shipping label as soon as possible."
            @action="fnApiGenerateShippingLabel" bannerType="statusSuccess" :loading="loading.generatingLabel"
            actionLabel="Generate shipping label"
            v-if="orderInformation.status == 'paid' && ((orderInformation.shipment_status == 'pending'))" />
        </div>
      </div>

      <div class="Polaris-Page__Content">
        <div class="Polaris-Card">
          <div class="Polaris-Card__Header">
            <div class="row">
              <div class="col">
                <h2 class="Polaris-Heading">Order information</h2>
              </div>
              <div class="col-auto" v-if=" (orderInformation.shipment_status == 'ready to ship') || (orderInformation.shipment_status == 'delivered') && !modalUpdateBOL">
                <div class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented" data-buttongroup-segmented="true">
                  <div class="Polaris-ButtonGroup__Item">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      @click="changeShowTracking(false)" :class=" !showTracking ? 'Polaris-Button--pressed':'' "
                      aria-pressed="true">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text"> Details </span>
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ButtonGroup__Item">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      @click="changeShowTracking(true)" :class=" showTracking ? 'Polaris-Button--pressed':'' "
                      aria-pressed="false">
                      <span class="Polaris-Button__Content">
                        <span class="Polaris-Button__Text">Shipment</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section">
            <div class="row justify-content-between ">
              <div class="col-auto"> <span v-text="'Number: '"></span> <strong
                  v-text=" orderInformation.number "></strong>
              </div>
              <div class="col-auto">
                <p class="text-truncate"> <span v-text=" 'Order: ' "></span>
                  <strong v-text=" fnST('general.' , orderInformation.order_status ) "
                    :class=" fnGetStatusClass( orderInformation.order_status ) "> </strong>
                </p>
              </div>
              <div class="col-auto">
                <p class="text-truncate"> <span v-text=" 'Payment: ' "></span>
                  <strong v-text=" fnST('general.' , orderInformation.status) "
                    :class=" fnGetStatusClass( orderInformation.status) "> </strong> </p>
              </div>

              <div class="col-auto">
                <p>
                  <span v-text=" 'Date: ' "></span>
                  <strong v-text=" fnDate( orderInformation.created_at,  CONST.DATE_FORMATS.DD_MM_YYYY )  "></strong>
                </p>
              </div>
            </div>
          </div>

          <div class="Polaris-Card__Section animated fadeIn " v-show=" !showTracking ">
            <div class="Polaris-Card__Subsection">

              <div class="row">
                <div class="col-12 col-md-6 ">
                  <div class="Polaris-Card ">
                    <div class="Polaris-Card__Header">
                      <div class="row justify-content-between">
                        <div class="col-7">
                          <h3 class="Polaris-Heading" v-text=" 'Origin (Warehouse)' "> </h3>
                        </div>
                        <div class="col-auto">
                          <button type="button"
                            @click="changeOriginAddress = !changeOriginAddress ; originAddressId = null " v-if=" orderInformation.shipment_status == 'pending' && orderInformation.status == 'paid'"
                            class="Polaris-Button Polaris-Button--primary Polaris-Button--sizeSlim Polaris-Button--iconOnly ">
                            <span class="Polaris-Button__Content">
                              <span class="Polaris-Button__Text mr-2"
                                v-text=" !changeOriginAddress ? 'Change' : 'Cancel' "> </span>
                              <i class="fa fa-pencil-alt"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="Polaris-Card__Section">
                      <div class="animated fadeIn" v-show="changeOriginAddress"  v-if=" orderInformation.shipment_status == 'pending'">
                        <div class="Polaris-FormLayout">
                          <div class="Polaris-FormLayout--grouped">
                            <v-validation rules="required" v-slot="{ errors }" :name=" 'Warehouse address' ">
                              <PolarisSelect v-model="originAddressId" :invalid=" errors[0] " :options=" addressesList "
                                label="Warehouse address" />
                            </v-validation>
                          </div>
                        </div>
                        <hr>
                      </div>
                      <!-- <pre> {{ originAddress }} </pre> -->
                      <div v-if=" originAddress.country ">
                        <p class="m-0">
                          <span
                            v-text=" $t('tables.name') + ': ' + originAddress.first_name + ' ' + originAddress.last_name "></span>
                        </p>
                        <p class="m-0">
                          <span v-text=" $t('tables.phone') + ': ' + originAddress.phone "></span>
                        </p>
                        <p class="m-0 ">
                          <span v-text=" $t('tables.address') + ': ' "></span>
                          <span v-text="fnFormatAddress( originAddress )"></span>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="Polaris-Card h-100 d-flex flex-column justify-content-between">
                    <div class="Polaris-Card__Header">
                      <h3 class="Polaris-Heading" v-text=" 'Destination' "> </h3>
                    </div>
                    <div class="Polaris-Card__Section">
                      <p class="m-0">
                        <span
                          v-text=" $t('tables.name') + ': ' + orderInformation.shipping_address.first_name + ' ' + orderInformation.shipping_address.last_name "></span>
                      </p>
                      <p class="m-0">
                        <span v-text=" $t('tables.phone') + ': ' + orderInformation.shipping_address.phone "></span>
                      </p>
                      <p class="m-0 ">
                        <span v-text=" $t('tables.address') + ': ' "></span>
                        <span v-text="fnFormatAddress( orderInformation.shipping_address )"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Polaris-Card__Subsection">

              <div class="Polaris-Card">
                <div class="Polaris-DataTable">
                  <div class="Polaris-DataTable__ScrollContainer">
                    <table class="Polaris-DataTable__Table">
                      <thead>
                        <tr>
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--firstColumn"
                            v-text=" 'Product'"> </th>
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            v-text=" 'Quantity' "> </th>
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            v-text=" 'Price' "> </th>
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--total"
                            v-text=" 'Total' "> </th>
                        </tr>
                      </thead>
                      <tbody v-show="  orderInformation.items.length > 0 ">
                        <tr class="Polaris-DataTable__TableRow" v-for=" (item, index) in  orderInformation.items "
                          :key="index">
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn "
                            scope="row">

                            <div class="row align-items-center">
                              <div class="col-auto">
                                <span class="Polaris-Thumbnail Polaris-Thumbnail--sizeSmall">
                                  <img v-image="item.image_url" alt="Product information" class="Polaris-Thumbnail__Image" >
                                </span>
                              </div>
                              <div class="col">
                                <p class="text-uppercase" v-show="item.sku">
                                  <small v-text=" 'Sku: ' + item.sku " class="text-muted "></small>
                                </p>
                                <p class=" "> <strong v-text=" item.name "></strong>
                                </p>
                                <p v-if="item.variant_name && item.variant_name != 'Default Title' "> <span
                                    v-text=" 'Variant: ' + item.variant_name "> </span> </p>
                              </div>
                            </div>

                          </th>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"
                            v-text="  item.quantity "> </td>

                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
                            <p> <span v-text=" fnFormatPrice( item.price , orderInformation.currency ) "></span>
                            </p>
                          </td>
                          <td
                            class=" text-success Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--total  ">
                            <p> <span v-text=" fnFormatPrice( item.total , orderInformation.currency ) "></span>
                            </p>
                          </td>

                        </tr>
                      </tbody>
                      <TableSkeleton v-show="orderInformation.length == 0 " />
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="Polaris-Card__Subsection">

              <div class="row justify-content-end mt-4 ">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 ">
                  <div class="Polaris-Card">
                    <div class="Polaris-Card__Section">

                      <div class="row ">
                        <div class="col-6">
                          <p> Subtotal: </p>
                        </div>
                        <div class="col-6 text-right ">
                          <span
                            v-text=" fnFormatPrice( orderInformation.totals.subtotal , orderInformation.currency ) "></span>
                        </div>
                        <div class="col-6">
                          <p v-text=" 'Discount: '"> </p>
                        </div>

                        <div class="col-6 text-right">
                          <span
                            v-text=" fnFormatPrice( orderInformation.totals.discount , orderInformation.currency ) "></span>
                        </div>

                        <div class="col-6">
                          <p v-text=" 'Tax: ' "> </p>
                        </div>
                        <div class="col-6 text-right ">
                          <span
                            v-text=" fnFormatPrice( orderInformation.totals.tax , orderInformation.currency ) "></span>

                        </div>
                        <div class="col-6 ">
                          <p v-text=" 'Shipping: ' "></p>
                        </div>
                        <div class="col-6 text-right">
                          <span
                            v-text=" fnFormatPrice( orderInformation.totals.shipping , orderInformation.currency )  "></span>
                        </div>
                        <div class="col-12">
                          <hr>
                        </div>
                        <div class="col-6">
                          <p v-text=" 'Total: ' "> </p>
                        </div>
                        <div class="col-6 text-right">
                          <strong class="text-success"
                            v-text=" fnFormatPrice( orderInformation.totals.total , orderInformation.currency ) "></strong>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="Polaris-Card__Section  " v-if=" (orderInformation.order_status != 'cancelled') && (orderInformation.shipment_status == 'pending' ) &&  !showTracking ">
            <div class="Polaris-SettingAction">
              <div class="Polaris-SettingAction__Setting">Delete order</div>
              <div class="Polaris-SettingAction__Action">
                <button type="button" class="Polaris-Button Polaris-Button--destructive"
                  @click="setModalCancelOrder(true)">
                  <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">Delete</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section animated fadeIn" v-show="showTracking">
            <TrackingOrder />
          </div>
        </div>

      </div>
    </div>


    <PolarisModal title="Cancel order" :visible="modalCancelOrder" @close-modal="resetCancelOrder() "
      @action-modal="fnValidateData()" :loading="loading.cancelOrder">
      <v-observer tag="form" class="mb-4 animated fadeIn" ref="formReasonToCancel" @submit.prevent="fnValidateData()">
        <div v-show=" !cancelReason.message ">
          <v-validation rules="required" v-slot="{ errors }" :name=" 'Reason' ">
            <div class="row">
              <div class="col-12" v-for=" (reason,index) in reasons " :key="index">
                <div class=" mb-1 " :class=" (reason.name == cancelReason.reason) ? 'bg-success ':'' ">
                  <div class=" ">
                    <div class="row align-items-center">
                      <div class="col-auto text-center">
                        <input type="radio" :id=" index +'reason'" :value="reason.name" v-model="cancelReason.reason"
                          class="ml-3">
                      </div>
                      <div class="col">
                        <label :for="index +'reason'" class="cursor-pointer p-2 m-0 w-100">
                          <strong v-text=" reason.name "></strong>
                          <br>
                          <span v-text=" reason.description "> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" p-2 my-2 bg-danger " v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </v-validation>
          <v-validation rules="required|min:8|max:500" v-slot="{ errors }" :name=" 'Comments' ">
            <div class="Polaris-FormLayout">
              <PolarisTextField label="Comments" :invalid=" errors[0] " v-model="cancelReason.comments" />
            </div>
          </v-validation>
        </div>
        <div v-if=" cancelReason.message ">
          <h4> <strong>You have reached the cancellation limit!</strong> </h4>

          <p class="mb-3">
          </p>

          <p class="mb-2">From this cancellation, you will be charged a fee of <strong>10%</strong> of the total order.
          </p>

          <p>
            <span> Canceling this order will create a chargeback of </span>
            <strong class="text-danger"
              v-text="fnFormatPrice( (orderInformation.totals.total * .10 ) , orderInformation.currency )"> </strong>
          </p>
          <br>
          <v-validation :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" :name=" 'Confirmation' ">
            <div class="Polaris-FormLayout">
              <PolarisCheckbox v-model="cancelReason.charge" :invalid=" errors[0] "
                label='I have read and accept the charges.' />
            </div>
          </v-validation>

          <p>
            <small> More information in <router-link to="/terms-&-conditions" target="_blank"> terms and conditions
              </router-link>.</small>
          </p>
        </div>


        <input type="submit" hidden>
      </v-observer>
    </PolarisModal>
  </div>
</template>

<script>
  import '@shopify/polaris/styles.css';
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  import TableSkeleton from './components/prtoducts-table-skeleton';
  import TrackingOrder from './components/tracking_order';
  import PolarisBanner from '@/components/shopify/polaris-banner';
  import PolarisModal from '@/components/shopify/polaris-modal';
  import PolarisTextField from '@/components/shopify/polaris-text-field';
  import PolarisCheckbox from '@/components/shopify/polaris-checkbox';
  import PolarisSelect from '@/components/shopify/polaris-select';


  export default {
    components: {
      TableSkeleton,
      PolarisCheckbox,
      PolarisBanner,
      TrackingOrder,
      PolarisModal,
      PolarisTextField,
      PolarisSelect,
    },
    data() {
      return {
        originAddressId: null,
        changeOriginAddress: false,
      };
    },
    computed: {
      ...mapState('ShopifyOrder', ['orderInformation', 'loading', 'showTracking', 'trackingInformation', 'reasons',
        'shippingInformation', 'cancelReason', 'modalCancelOrder', 'modalUpdateBOL', 'orderCharge', 'originAddress',
        'addressesList', 'store'
      ]),
    },
    watch: {
      originAddressId() {
        this.fnSetNewOriginAddress();
      }
    },
    methods: {
      ...mapActions('ShopifyOrder', ['fnApiGetOrder', 'fnApiCancelOrder', 'fnApiGenerateShippingLabel',
        'fnApiUpdateCharge'
      ]),
      ...mapMutations('ShopifyOrder', ['changeShowTracking', 'setModalCancelOrder', 'resetCancelOrder',
        'setOriginAddress'
      ]),
      async fnValidateData() {
        if (await this.$refs['formReasonToCancel'].validate()) {
          this.fnApiCancelOrder();
        }
      },
      fnSetNewOriginAddress() {
        if ((this.originAddressId != null) && (this.originAddressId != '')) {
          this.setOriginAddress({
            ...this.store.addresses.find(address => address.id == this.originAddressId)
          });
        } else {
          this.setOriginAddress({
            ...this.shippingInformation.origin
          });
        }
      }
    },
    mounted() {
      this.fnApiGetOrder(this.$route.params.id);
    },
    beforeDestroy() {
      this.resetCancelOrder();
    }
  }
</script>

<style>

</style>